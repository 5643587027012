import React, { useState } from 'react';
import { Header, InputField, Sidebar } from '../../Components';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GeneralAgreements = () => {
  const { t } = useTranslation();
  const [BTWNummer, setBTWNummer] = useState('');
  const [NaamVennootschap, setNaamVennootschap] = useState('');
  const [AdresVennootschap, setAdresVennootschap] = useState('');
  const [email, setEmail] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState<any>(null);

  const handleField1Change = (e: any) => {
    setBTWNummer(e.target.value);
  };

  const handleField2Change = (e: any) => {
    setNaamVennootschap(e.target.value);
  };

  const handleField3Change = (e: any) => {
    setAdresVennootschap(e.target.value);
  };

  const handleField4Change = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (NaamVennootschap.length > 250) {
      toast.error('Naam Vennootschap exceeds the maximum character limit of 250!');
      return;
    }
    if (AdresVennootschap.length > 250) {
      toast.error('Adres Vennootschap exceeds the maximum character limit of 250!');
      return;
    }
    const showData = {
      id: localStorage.getItem('rest_id'),
      btw_number: BTWNummer,
      company_name: NaamVennootschap,
      company_address: AdresVennootschap,
      restaurant_email:email
    };
    setError(null);

    try {
      const response = await fetch('https://api-staging.eatsy.be/restaurant/createdocument/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(showData),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const result = await response.json();
      setApiResponse(result);
      toast.success('Submitted successfully!');
    } catch (error: any) {
      setError(error.toString());
      toast.error('Failed to submit the data.');
    }
  };

  return (
    <>
      <div>
      <Sidebar />
        <Header />
        <div className="col" style={{ width: "100%", height: "100%", display: "inline-grid", justifyContent: "center", marginTop: "15%" }}>
          <div 
            style={{fontSize:"17px",fontWeight:"bold",display:"flex",justifyContent:"center" }}>
            <span>
              {/* Upload CSV */}
              {t("GeneralAgreements.General_Agreements_title")}
            </span>
          </div>
          <div>
          <form style={{ fontSize: "19px",width:"100%"}} onSubmit={handleSubmit}>
            <label htmlFor="BTWNummer"className="small">
              <b>{t('GeneralAgreements.GeneralAgreements_label_BTW')}</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={BTWNummer}
              onChange={handleField1Change}
              placeholder="Enter BTW Nummer"
              id="BTWNummer"
            />
            <label htmlFor="NaamVennootschap" className="small">
              <b>{t('GeneralAgreements.GeneralAgreements_label_Naam')}</b>
            </label>
            <InputField
              className="form-control"
              type="text"
              value={NaamVennootschap}
              onChange={handleField2Change}
              placeholder="Enter Naam Vennootschap"
              id="NaamVennootschap"
            />
            <label htmlFor="AdresVennootschap" className="small">
              <b>{t('GeneralAgreements.GeneralAgreements_label_Adres')}</b>
            </label>
            <InputField
              className="form-control"
              type="text"
              value={AdresVennootschap}
              onChange={handleField3Change}
              placeholder="Enter Adres Vennootschap"
              id="AdresVennootschap"
            />
            <label htmlFor="email" className="small">
              <b>{t('GeneralAgreements.GeneralAgreements_email')}</b>
            </label>
            <InputField
              className="form-control"
              type="email"
              value={email}
              onChange={handleField4Change}
              placeholder="Enter Email"
              id="email"
            />
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", }}>
              <button
                style={{
                  width: "40%",
                  backgroundColor:"red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius:"0.25rem",
                }}
                type="submit">
                Submit
              </button>
            </div>
          </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default GeneralAgreements;

